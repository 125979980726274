import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import withAuth from '../users/withAuth';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';

import '../styles/admin_service.css';
import AddServiceModal from '../admin/AddServiceModel';

function Admin_service() {

    const [currentDisplayedService, setCurrentDisplayedService] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [inputValues, setInputValues] = useState({});
    const [inputChanged, setInputChanged] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [showAddServiceModal, setShowAddServiceModal] = useState(false);


    const handleShowAddServiceModal = () => {
      setShowAddServiceModal(true);
    };

    const handleCloseAddServiceModal = () => {
      setShowAddServiceModal(false);
    };


    const handleShowModal = (service) => {
      setSelectedService(service);
      setShowModal(true);
    };
    
  
    const handleCloseModal = () => {
      setShowModal(false);
      window.location.reload();
    };


    const handleInputChange = (e, field) => {
      const { value } = e.target;
      setInputValues({
          ...inputValues,
          [field]: value,
      });
      setInputChanged({
          ...inputChanged,
          [field]: true,
      });
  };






  const handleUpdateUnitCost = async (serviceName) => { 
    try {
      const updateData = { serviceName: serviceName, unit_cost: inputValues['unit_cost'] };   
      const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/update_unitCost', updateData);
      // const response = await axios.post('http://localhost:8085/update_unitCost', updateData);
      if (response.status === 200) 
      {
        console.log('Unit cost updated successfully');
      }
      else 
      {
        setErrorMessage('Sorry Unit Cost did not get updated. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating the service. Please try again.');
    }
  };



  const handleUpdateDiscount = async (serviceName) => {
    const updateData = { serviceName: serviceName, discount: inputValues['discount'] };    
    try {
      const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/update_discount', updateData);
      // const response = await axios.post('http://localhost:8085/update_discount', updateData);
      if (response.status === 200) 
      {
        console.log('Unit cost updated successfully');
      }
      else 
      {
        setErrorMessage('Sorry discount did not get updated. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating the service. Please try again.');
    }
  };


  const handleUpdateMinUnit = async (serviceName) => {
    const updateData = { serviceName: serviceName, min_unit: inputValues['min_unit'] };    
    try {
      const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/update_minUnit', updateData);
      // const response = await axios.post('http://localhost:8085/update_minUnit', updateData);
      if (response.status === 200) {
        console.log('Unit cost updated successfully');
      }
      else 
      {
        setErrorMessage('Sorry Min Units did not get updated. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating the service. Please try again.');
    }
  };



  const handleUpdateMinCharge = async (serviceName) => {
    const updateData = { serviceName: serviceName, min_charge: inputValues['min_charge'] };    
    try {
      const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/update_minCost', updateData);
      // const response = await axios.post('http://localhost:8085/update_minCost', updateData);
      if (response.status === 200) 
      {
        console.log('Unit cost updated successfully');
      }
      else 
      {
        setErrorMessage('Sorry Min Cost did not get updated. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating the service. Please try again.');
    }
  };
  



    const fetchServiceData = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/service_detail', {});
        // const response = await axios.post('http://localhost:8085/service_detail', {});
        setCurrentDisplayedService(response.data);
      } catch (error) {
        setErrorMessage('Error while fetching the data.');
        console.error('Error fetching services data failed. :', error);
      }
    };
    


    const handleDeleteService = async (serviceName) => {
      const confirmation = window.confirm(`Are you sure you want to delete the service ${serviceName} ?`);
      if (confirmation) {
        try {
          const response1 = await axios.post(`https://tech.cle.org.pk:8085/service_delete/${serviceName}`);
          // const response1 = await axios.post(`http://localhost:8085/service_delete/${serviceName}`);
          if (response1.status == 200) 
          {
            setErrorMessage('');
            window.location.reload();
          } else 
          {
            setErrorMessage('Sorry service did not get deleted. Try again.');
          }
        } catch (error) {
          console.error('Error deleting service:', error);
          setErrorMessage('An error occurred while deleting the service. Please try again.');
        }
      }
    };




  useEffect(() => {
    fetchServiceData();
  }, []);



  const renderServiceList = () => {
    return currentDisplayedService.map((service) => (
      <tr key={service.serviceName}> 
        <td className="w-sname"> {service.serviceName}</td>
        <td className="w-stitle"> {service.serviceTitle}</td>
        <td className="w-unitcost">{service.unit_cost}</td>
        <td className="w-discount">{service.discount}</td>
        <td className="w-minunit">{service.min_unit}</td>
        <td className="w-mincharge">{service.min_charge}</td>
        <td className="w-actions">
          <button type="button" className="btn btn-primary changeServiceBtn" onClick={() => handleShowModal(service)}>View/Update</button>
          <button type="button" className="btn btn-danger btn-font" onClick={() => handleDeleteService(service.serviceName)}>Delete</button>
        </td>
      </tr>
    ));
  };

  


    return (

<div className='admin-service-page'>
    <Helmet>
        <title>Service Management</title>
    </Helmet>

    <div style={{marginTop:"-1%"}}></div>

    <div className="breadcrumb">
        <ol className="container breadcrumb">
            <li className="breadcrumb-item active admin-home">
                <a href="/" className='admin-home-text'> Home</a>
                 <a> / </a>
                <a href="admin_service" className='admin-home-text'>Service Management</a>
            </li>
        </ol>
    </div>

    <div>
      <button type="button" id="addservbtn" className="btn btn-success" onClick={handleShowAddServiceModal}>Add New Service</button>
      <AddServiceModal show={showAddServiceModal} onClose={handleCloseAddServiceModal} />
    </div>
    { errorMessage && <p className='login-error-clr '>{errorMessage}</p>}


{/* section 2  */}
    <div className='service-management'> 

      <div className="container-admin-services">
        <div className="admin-panel">
            <div className="admin-services-heading">Existing Services</div>
            <div className="admin-panel-body">
                <table className="admin-table table-bordered">
                    <thead>
                        <tr>
                            <th>Service Name</th>
                            <th>Service Title</th>
                            <th>Unit Cost</th>
                            <th>Discount(%)</th>
                            <th>Min Units</th>
                            <th>Min Charge</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>{renderServiceList()}</tbody>
                </table>
            </div>
        </div>
      </div>


      
{/* section 3  */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton> 
          <Modal.Title className='btn-font'>{selectedService ? selectedService.serviceTitle : "Service Title"}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {selectedService && (
            <div className='service-form-properties'>
              <div className="form-group services-form-group">
                <label className='service-form-label'>Service Name:</label>
                <input type="text" className="form-control service-form-box" value={selectedService.serviceName} readOnly />
              </div>

              <div className="form-group services-form-group">
                <label className='service-form-label'>Service Title:</label>
                <input type="text" className="form-control service-form-box" value={selectedService.serviceTitle} readOnly/>
              </div>


              <div className="form-group services-form-group">
                  <label className={`service-form-label ${inputChanged['unit_cost'] ? 'green-text' : ''}`}> Unit Cost: </label>
                  <input
                    type="text"
                    className={`form-control service-form-box ${inputChanged['unit_cost'] ? 'green-border' : ''}`}
                    value={inputValues['unit_cost'] || selectedService.unit_cost}
                    onChange={(e) => handleInputChange(e, 'unit_cost')}
                  />
                  {inputChanged['unit_cost'] && (
                      <button type="button" className="btn btn-success update-button" onClick={() => handleUpdateUnitCost(selectedService.serviceName)}> Update </button> 
                      )}
              </div>

              <div className="form-group services-form-group">
                  <label className={`service-form-label ${inputChanged['discount'] ? 'green-text' : ''}`}> Discount: </label>
                  <input
                      type="text"
                      className={`form-control service-form-box ${inputChanged['discount'] ? 'green-border' : ''}`}
                      value={inputValues['discount'] || selectedService.discount}
                      onChange={(e) => handleInputChange(e, 'discount')}
                  />
                  {inputChanged['discount'] && (
                      <button type="button" className="btn btn-success update-button" onClick={() => handleUpdateDiscount(selectedService.serviceName)} > Update </button>
                  )}
              </div>


              <div className="form-group services-form-group">
                  <label className={`service-form-label ${inputChanged['min_unit'] ? 'green-text' : ''}`}> Minimum Unit: </label>
                  <input
                      type="text"
                      className={`form-control service-form-box ${inputChanged['min_unit'] ? 'green-border' : ''}`}
                      value={inputValues['min_unit'] || selectedService.min_unit}
                      onChange={(e) => handleInputChange(e, 'min_unit')}
                  />
                  {inputChanged['min_unit'] && (
                      <button type="button" className="btn btn-success update-button" onClick={() => handleUpdateMinUnit(selectedService.serviceName)}>Update </button>
                  )}
              </div>

              <div className="form-group services-form-group">
                  <label className={`service-form-label ${inputChanged['min_charge'] ? 'green-text' : ''}`}> Minimum Charge: </label>
                  <input
                      type="text"
                      className={`form-control service-form-box ${inputChanged['min_charge'] ? 'green-border' : ''}`}
                      value={inputValues['min_charge'] || selectedService.min_charge}
                      onChange={(e) => handleInputChange(e, 'min_charge')}/>
                    
                    {inputChanged['min_charge'] && (
                      <button type="button" className="btn btn-success update-button" onClick={() => handleUpdateMinCharge(selectedService.serviceName)}> Update </button>
                    )}
              </div>

            </div>
          )}
        </Modal.Body>
        
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-danger btn-font" onClick={handleCloseModal}> Close </Button>
        </Modal.Footer>

      </Modal>



    </div>

</div>
    )

}
// export default Admin_service;

const AdminServiceWithAuth = withAuth(Admin_service);
export default AdminServiceWithAuth;