import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/static_summarization.css';
import '../styles/font_family.css';

import tts_api from '../icons/tts/api-ref-icon.png';

export default function Textsummerization() {

  return (


    <div id="content-sum"> 

        <Helmet>
            <title>CLE Urdu Text Summarization Service</title>
        </Helmet>

        <div className="row sum-banner">
                <div className="sum-banner-title">URDU TEXT SUMMARIZATION SERVICE </div>
        </div>


        <div className="row sum-service-row">
          
            <div className="col-lg-8">
                <div className="row">
                    <div className="sum-service-text">
                    <p> CLE Urdu text summarization service takes Urdu text as input and produces its abridged version as output using an extractive summarization technique. The length of the summary depends on compression ratio specified by the user.</p>
                    </div>
                </div>
                <div className="row">
                    <a href="/register" className="sum-service-btn">  
                        START SERVICE FOR FREE
                    </a>
                </div>
            </div>

            <div className="col-lg-4">
                <a href="/static_api_textsummerization">
                    <div className="sum-api-ref">
                        <img src={tts_api} alt='sum api'/>
                        <div className="sum-api-ref-text">API REFERENCE</div>
                    </div>
                </a>
            </div>
        </div>
  
    </div>



  )
}

