import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import React from 'react';

import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import 'bootstrap-icons/font/bootstrap-icons.css';

import WhiteNavBar from './layout/White_NavBar';
import BlackNavBar from './layout/Black_NavBar';
import DashboardNavbar from './layout/Dashboard_NavBar';
import Admin_NavBar from "./layout/Admin_NavBar";
import Footer from './layout/Footer'; 
import Footer_Login from "./layout/Footer_login";
import Admin_Footer from "./layout/Admin_Footer";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Pricing from "./pages/Pricing";
import Confirm_account from "./users/Confirm_account";
import Fellowship from "./pages/Fellowship";
import Fellowship_award from "./pages/Fellowship_award";

import TTS_NavBar from "./Services_navbar/TTS";
import PUNJABI_TTS_NavBar from "./Services_navbar/PunjabiTTS";
import ASR_NavBar from "./Services_navbar/ASR";
import Keyword_NavBar from "./Services_navbar/Keyword";
import POSTagger_NavBar from "./Services_navbar/POSTag";
import Summarization_NavBar from "./Services_navbar/Summarization";
import Stemmer_NavBar from "./Services_navbar/Stemmer";
import Domain_NavBar from "./Services_navbar/Domain";
import Roman_NavBar from "./Services_navbar/Roman";
import Spell_NavBar from "./Services_navbar/Spell";
import LangID_NavBar from "./Services_navbar/LangID";
import Profanity_NavBar from "./Services_navbar/Profanity";

import LandingPage from '../src/pages/LandingPage';
import Register from '../src/users/Register';
import LoginPage from '../src/users/LoginPage';
import Speech_services from "./pages/Speech_services";
import Text_services from "./pages/Text_services";
import Image_services from "./pages/Image_services";

import HomePageWithAuth from '../src/pages/Home';
import HistoryPageWithAuth from "./pages/History";
import PricingPageWithAuth from "./pages/Pricing_login";
import ForgetPassword from './users/ForgetPassword';
import ResetPassword from "./users/ResetPassword";
import AccesstokenWithAuth from "./users/Accesstoken";
import UserInfoWithAuth from "./users/UserInfo";
import ResetPasswordWithAuth from "./users/ResetPassword_after_login";
import ContactLoginWithAuth from "./pages/Contact_login";

import AdminHomeWithAuth from "./admin/Admin_Home";
import AdminUserWithAuth from "./admin/Admin_users";
import AdminServiceWithAuth from "./admin/Admin_service";
import SummaryWithAuth from "./admin/ViewSummaryModel";
import ViewCostingModel from "./admin/ViewCostingModel.js";

import FemaleTTSWithAuth from './text_to_speech/FemaleTTS';
import FemaleTTSTacatronWithAuth from "./text_to_speech/FemaleTTSTacatron.js";
import MaleTTSWithAuth from "./text_to_speech/MaleTTS";
import MalePunjabiTTSWithAuth from "./punjabi_text_to_speech/MaleTTS";
import AudioStreamWithAuth from './speech_to_text/AudioStream';
import POS_TaggerWithAuth from './POS_Tagger/POS_Tagger';
import Text_SummerizationWithAuth from "./text_summerization/Text_Summerization";
import Keyword_ExtractionWithAuth from "./keyword_extraction/Keyword_Extraction";
import Domain_IdentificationWithAuth from "./domain_identification/Domain_Identification";
import Spell_CheckerWithAuth from "./spell_checker/Spell_Checker";
import Urdu_StemmerWithAuth from "./stemmer/Urdu_stemmer";
import RomanizationWithAuth from "./romanization/Romanization";
import LangIDWithAuth from "./langID/LanguageID";
import ProfanityEstimationWithAuth from "./profanity_estimation/ProfanityEstimation";

import API_spellcheckerWithAuth from "./spell_checker/API_spellchecker";
import API_PostaggerWithAuth from "./POS_Tagger/API_Postagger";
import API_TTSWithAuth from "./text_to_speech/API_TTS";
import API_PUNJABITTSWithAuth from "./punjabi_text_to_speech/API_TTS";
import API_domainIdentificationWithAuth from "./domain_identification/API_domainIdentification";
import API_textsummarizationWithAuth from "./text_summerization/API_textsummarization";
import API_keywordextractionWithAuth from "./keyword_extraction/API_keywordextraction";
import API_stemmerWithAuth from "./stemmer/API_stemmer";
import API_ASRWithAuth from "./speech_to_text/API_ASR";
import API_romanizationWithAuth from "./romanization/API_romanization";
import API_LangIDWithAuth from "./langID/API_LangID";
import API_ProfanityWithAuth from "./profanity_estimation/API_Profanity";

import TTS from "./text_to_speech/TTS";
import PTTS from "./punjabi_text_to_speech/TTS";
import ASR from "./speech_to_text/ASR";
import Postagger from "./POS_Tagger/postagger";
import Textsummerization from './text_summerization/textsummerization';
import Keywordextraction from "./keyword_extraction/keywordExtraction";
import Domain from "./domain_identification/domainIdentification";
import Spell from "./spell_checker/spellchecker";
import Stemmer from "./stemmer/urdustemmer";
import Roman from "./romanization/roman";
import LanguageID from "./langID/langid";
import Profanity from "./profanity_estimation/profanity";

import Static_API_domainIdentification from "./domain_identification/Static_API_domainIdentification";
import Static_API_keywordextraction from "./keyword_extraction/Static_API_keywordextraction";
import Static_API_Postagger from "./POS_Tagger/Static_API_Postagger";
import Static_API_romanization from "./romanization/Static_API_romanization";
import Static_API_ASR from "./speech_to_text/Static_API_ASR";
import Static_API_spellchecker from "./spell_checker/Static_API_spellchecker";
import Static_API_stemmer from "./stemmer/Static_API_stemmer";
import Static_API_TTS from "./text_to_speech/Static_API_TTS";
import Static_API_PTTS from "./punjabi_text_to_speech/Static_API_TTS";
import Static_API_textsummarization from "./text_summerization/Static_API_textsummarization";
import Static_API_LangID from "./langID/Static_API_LangID";
import Static_API_Profanity from "./profanity_estimation/Static_API_Profanity";


function App() {
  console.log("Project Env = ", process.env.REACT_APP_TECH_SERVICE)

  const isAdminUser = () => {
    const email = localStorage.getItem('email'); 
    return email && email.includes('@tech.cle.org.pk');
  };

  if (isAdminUser())  {
    return (
        <div className="App">
          <Router>
            <Routes>
              <Route exact path="/" element={<> <Admin_NavBar /> <AdminHomeWithAuth /> <Admin_Footer /> </>} />
              <Route exact path="/admin_service" element={<> <Admin_NavBar /> <AdminServiceWithAuth /> <Admin_Footer /> </>} />
              <Route exact path="/admin_users" element={<> <Admin_NavBar /> <AdminUserWithAuth /> <Admin_Footer /> </>} />
              <Route exact path="/users_summary/:accessToken" element={<> <Admin_NavBar /> <SummaryWithAuth /> <Admin_Footer /> </>} />
              <Route exact path="/viewjobdetails/:jobId" element={<> <Admin_NavBar /> <HistoryPageWithAuth /> <Admin_Footer /> </>} />
              <Route exact path="/users_costing/:emailId" element={<> <Admin_NavBar /> <ViewCostingModel /> <Admin_Footer /> </>} />
            </Routes>
          </Router>
        </div>
    );
} else {
    return (
        <div className="App">
          <Router>
            <Routes>
              <Route exact path="/landingPage" element={<> <WhiteNavBar /> <LandingPage /> <Footer /> </>}/> 
              <Route exact path="/speech_services" element={<> <WhiteNavBar /> <Speech_services /> <Footer /> </>}/> 
              <Route exact path="/text_services" element={<> <WhiteNavBar /> <Text_services /> <Footer /> </>}/> 
              <Route exact path="/image_services" element={<> <WhiteNavBar /> <Image_services /> <Footer /> </>}/>
              <Route exact path="/forgetPassword" element={<> <WhiteNavBar /> <ForgetPassword/> <Footer /> </>} />
              <Route exact path="/caccount" element={<> <WhiteNavBar /> <Confirm_account/> <Footer /> </>} />
              <Route exact path="/resetPassword" element={<> <WhiteNavBar /> <ResetPassword/> <Footer /> </>} />
              <Route exact path="/pricing" element={<> <WhiteNavBar /> <Pricing/> <Footer /> </>} />
              <Route exact path="/fellowship" element={<> <WhiteNavBar /> <Fellowship/> <Footer /> </>} />
              <Route exact path="/fellowship_proposal" element={<> <WhiteNavBar /> <Fellowship_award/> <Footer /> </>} />

              <Route exact path="/tts" element={<> <WhiteNavBar /> <TTS /> <Footer /> </>} />
              <Route exact path="/ptts" element={<> <WhiteNavBar /> <PTTS /> <Footer /> </>} />
              <Route exact path="/stt" element={<> <WhiteNavBar /> <ASR /> <Footer /> </>} />
              <Route exact path="/postagger" element={<> <WhiteNavBar /> <Postagger /> <Footer /> </>} />
              <Route exact path="/textsummerization" element={<> <WhiteNavBar /> <Textsummerization /> <Footer /> </>} />
              <Route exact path="/keywordextraction" element={<> <WhiteNavBar /> <Keywordextraction /> <Footer /> </>} />
              <Route exact path="/domainidentification" element={<> <WhiteNavBar /> <Domain /> <Footer /> </>} />
              <Route exact path="/spell" element={<> <WhiteNavBar /> <Spell /> <Footer /> </>} />   
              <Route exact path="/stemmer" element={<> <WhiteNavBar /> <Stemmer /> <Footer /> </>} /> 
              <Route exact path="/roman" element={<> <WhiteNavBar /> <Roman /> <Footer /> </>} />   
              <Route exact path="/langid" element={<> <WhiteNavBar /> <LanguageID /> <Footer /> </>} />
              <Route exact path="/profanity" element={<> <WhiteNavBar /> <Profanity /> <Footer /> </>} />

              <Route exact path="/static_api_tts" element={<> <WhiteNavBar /> <Static_API_TTS /> <Footer /> </>} />
              <Route exact path="/static_api_ptts" element={<> <WhiteNavBar /> <Static_API_PTTS /> <Footer /> </>} />
              <Route exact path="/static_api_stt" element={<> <WhiteNavBar /> <Static_API_ASR /> <Footer /> </>} />
              <Route exact path="/static_api_postagger" element={<> <WhiteNavBar /> <Static_API_Postagger /> <Footer /> </>} />
              <Route exact path="/static_api_textsummerization" element={<> <WhiteNavBar /> <Static_API_textsummarization /> <Footer /> </>} />
              <Route exact path="/static_api_keywordextraction" element={<> <WhiteNavBar /> <Static_API_keywordextraction /> <Footer /> </>} /> 
              <Route exact path="/static_api_domainidentification" element={<> <WhiteNavBar /> <Static_API_domainIdentification /> <Footer /> </>} />
              <Route exact path="/static_api_spell" element={<> <WhiteNavBar /> <Static_API_spellchecker /> <Footer /> </>} />   
              <Route exact path="/static_api_stemmer" element={<> <WhiteNavBar /> <Static_API_stemmer /> <Footer /> </>} /> 
              <Route exact path="/static_api_roman" element={<> <WhiteNavBar /> <Static_API_romanization /> <Footer /> </>} />  
              <Route exact path="/static_api_langid" element={<> <WhiteNavBar /> <Static_API_LangID /> <Footer /> </>} />  
              <Route exact path="/static_api_profanity" element={<> <WhiteNavBar /> <Static_API_Profanity /> <Footer /> </>} /> 

              <Route exact path="/register" element={<> <BlackNavBar /> <Register /> <Footer /> </>} />
              <Route exact path="/loginpage" element={<> <BlackNavBar /> <LoginPage /> <Footer /> </>} />
              <Route exact path="/privacy" element={<> <BlackNavBar /> <Privacy /> <Footer /> </>} />
              <Route exact path="/terms" element={<> <BlackNavBar /> <Terms /> <Footer /> </>} />

              <Route exact path="/home" element={<> <DashboardNavbar /> <HomePageWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/viewjobdetails/:jobId" element={<> <DashboardNavbar /> <HistoryPageWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/" element={<> <DashboardNavbar /> <HomePageWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/access_token" element={<> <DashboardNavbar /> <AccesstokenWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/reset_password" element={<> <DashboardNavbar /> <ResetPasswordWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/user_info" element={<> <DashboardNavbar /> <UserInfoWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/contact_login" element={<> <DashboardNavbar /> <ContactLoginWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/pricing_login" element={<> <DashboardNavbar /> <PricingPageWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/privacy_login" element={<> <DashboardNavbar /> <Privacy /> <Footer_Login /> </>} />
              <Route exact path="/terms_login" element={<> <DashboardNavbar /> <Terms /> <Footer_Login /> </>} />

              <Route exact path="/api_spell" element={<> <DashboardNavbar /> <API_spellcheckerWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/api_pos" element={<> <DashboardNavbar /> <API_PostaggerWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/api_tts" element={<> <DashboardNavbar /> <API_TTSWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/punjabi_api_tts" element={<> <DashboardNavbar /> <API_PUNJABITTSWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/api_domain" element={<> <DashboardNavbar /> <API_domainIdentificationWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/api_summarization" element={<> <DashboardNavbar /> <API_textsummarizationWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/api_keyword" element={<> <DashboardNavbar /> <API_keywordextractionWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/api_stemmer" element={<> <DashboardNavbar /> <API_stemmerWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/api_asr" element={<> <DashboardNavbar /> <API_ASRWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/api_roman" element={<> <DashboardNavbar /> <API_romanizationWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/api_langid" element={<> <DashboardNavbar /> <API_LangIDWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/api_profanity" element={<> <DashboardNavbar /> <API_ProfanityWithAuth /> <Footer_Login /> </>} />

              <Route exact path="/utts" element={<> <TTS_NavBar /> <FemaleTTSWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/female_tts" element={<> <TTS_NavBar /> <FemaleTTSTacatronWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/male_tts" element={<> <TTS_NavBar /> <MaleTTSWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/punjabi_male_tts" element={<> <PUNJABI_TTS_NavBar /> <MalePunjabiTTSWithAuth /> <Footer_Login /> </>} />
              
              <Route exact path="/asr" element={<> <ASR_NavBar /> <AudioStreamWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/pos_tagger" element={<> <POSTagger_NavBar /> <POS_TaggerWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/Text_Summerization" element={<> <Summarization_NavBar /> <Text_SummerizationWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/Keyword_Extraction" element={<> <Keyword_NavBar /> <Keyword_ExtractionWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/Domain_Identification" element={<> <Domain_NavBar /> <Domain_IdentificationWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/Spell_Checker" element={<> <Spell_NavBar /> <Spell_CheckerWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/Urdu_stemmer" element={<> <Stemmer_NavBar /> <Urdu_StemmerWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/Urdu_romanization" element={<> <Roman_NavBar /> <RomanizationWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/Language_identification" element={<> <LangID_NavBar /> <LangIDWithAuth /> <Footer_Login /> </>} />
              <Route exact path="/Profanity_estimation" element={<> <Profanity_NavBar /> <ProfanityEstimationWithAuth /> <Footer_Login /> </>} />
            </Routes>
          </Router>
      </div>
    );
  }
}

export default App;
