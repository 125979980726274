import React, { useState , useEffect} from 'react';
import { Helmet } from 'react-helmet';
import '../styles/confirm_account.css';


export default function Confirm_account() {
  const [errorMessage, setErrorMessage] = useState('Your account has been confirmed.');
  const [token, setToken] = useState('');


  useEffect(() => {
    // Extract the token from the URL parameter
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get('token');

    if (tokenFromUrl) 
    {
      setToken(tokenFromUrl);
      confirmUserAccount(tokenFromUrl);

    } else {
      setErrorMessage('Token not found in the URL.');
    }
  }, []);


  async function confirmUserAccount(token) {
    try {
        const response = await fetch(process.env.REACT_APP_TECH_SERVICE+'/confirm_user', {
        // const response = await fetch('http://localhost:8085/confirm_user', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }),
      });

      if (response.ok) 
      {
      } 
      else {
        setErrorMessage('User account not verified.');
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  }






  return (
    <div className="confirm-page">
      <Helmet>
        <title>Account Confirmation</title>
      </Helmet>

          <div className='mail-send-msg'>
              <p>{errorMessage}</p>
          </div>
          



    </div>
  );
}
