import React from 'react'
import { Nav , NavDropdown } from 'react-bootstrap';

import logo from '../icons/navbar/cle-markaz-white.png';
import profile_pic from '../icons/navbar/profile-icon-blue.png';
import '../styles/black_navbar.css';


export default function Black_NavBar() {


  return (

  <nav className="navbar navbar-expand-lg navbar-light black-bg">

          <a href="/landingPage" className="navbar-brand" style= {{ marginLeft: '15%', fontWeight:"bold"}}>
              <img id="logo" className='logo-pic' src={logo} alt='logo'></img>
              <p className='black-navbar-text-of-logo' >Center For Language Engineering</p>
          </a>

          <a style= {{ marginLeft: '30%'}}>

                <li className="nav-item dropdown profile-pic">
                  <button className="btn btn" data-bs-toggle="dropdown">
                    <img src={profile_pic} alt="Profile pic"/>
                  </button>

                  <ul className="dropdown-menu drop-menu">
                    <li><a href='/loginpage' className='dropdown-text'>Sign in</a></li>
                    <li><a href="/register" className='dropdown-text'>Register</a></li>
                  </ul>
                </li>


                <button style={{fontFamily:"Raleway"}} className="navbar-toggler white menu-class-black" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span>Menu</span>
                </button>


                <div className="collapse navbar-collapse menu-class-black-drop" id="collapsibleNavbar">
                    <ul className="navbar-nav ms-auto">

                      <li className="nav-item">
                        <Nav.Link href="/landingPage" className='white-color cyan-bar' style={{fontWeight:"bold"}}>HOME</Nav.Link>
                      </li>


                      <div className="dropdown">
                        <Nav.Link className='dropbtn-b'style={{fontWeight:'bold'}} >SERVICES &#9660;</Nav.Link>
                        <div className="dropdown-content">
                          <div className="service-table">
                            <div className="row">
                              <div className="column">
                                <h3>SPEECH PROCESSING</h3>
                                <ul>
                                  <li><a href="/tts" style={{color:'black'}}>URDU TEXT-TO-SPEECH</a></li>
                                  <li><a href="/stt" style={{color:'black'}}>URDU SPEECH-TO-TEXT</a></li>
                                  <li><a href="/tts" style={{color:'black'}}>PUNJABI TEXT-TO-SPEECH</a></li>
                                  {/* <li><a href="/subOption3">SPEECH-TO-SPEECH</a></li> */}
                                </ul>
                              </div>
                              <div className="column">
                                <h3>TEXT PROCESSING</h3>
                                <ul>
                                  <li><a href="/postagger" style={{color:'black'}}>URDU PARTS-OF-SPEECH</a></li>
                                  <li><a href="/profanity" style={{color:'black'}}>URDU CONTENT PROFANITY ESTIMATION</a></li>
                                  <li><a href="/langid" style={{color:'black'}}>URDU LANGUAGE IDENTIFICATION</a></li> 
                                  <li><a href="/textsummerization" style={{color:'black'}}>URDU TEXT SUMMARIZATION</a></li>
                                  <li><a href="/domainidentification" style={{color:'black'}}>URDU DOMAIN IDENTIFICATION</a></li>
                                  <li><a href="/spell" style={{color:'black'}}>URDU SPELL CHECKING</a></li>
                                  <li><a href="/keywordextraction" style={{color:'black'}}>URDU KEYWORD EXTRACTION</a></li>
                                  {/* <li><a href="/roman" style={{color:'black'}}>ROMAN TO URDU SCRIPT</a></li> */}
                                  <li><a href="/stemmer" style={{color:'black'}}>URDU STEMMER</a></li>
                                  {/* <li><a href="/subOption5" style={{color:'black'}}>ASPECT BASED SENTIMENT ANALYSIS</a></li>
                                  <li><a href="/subOption5" style={{color:'black'}}>URDU SEGMENTATION</a></li> */}
                                </ul>
                              </div>
                              <div className="column">
                                <h3>IMAGE PROCESSING</h3>
                                <ul>
                                  {/* <li><a href="/subOption7" style={{color:'black'}}>URDU OPTICAL CHARACTER RECOGNITION</a></li>
                                  <li><a href="/subOption8" style={{color:'black'}}>CAMERA CAPTURED ADDRESS RECOGNITION AND STRUCTURING</a></li> */}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <li className="nav-item">
                      <Nav.Link href="/pricing" className='white-color cyan-bar' style={{fontWeight:"bold"}}>PRICING</Nav.Link>
                      </li>

                      <li className="nav-item">
                      <Nav.Link href="/landingPage#get-started-container" className='get-started cyan-bar' style={{fontWeight:"bold"}}>GET STARTED</Nav.Link>
                      </li>


                    </ul>
                  </div>

            </a>

  </nav> 




  );

}
